import React, { useState } from "react";
import './index.scss'
import Header from '../Header'

function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };
    return [storedValue, setValue];
}


function Layout(props) {
    const { children } = props;
    const [count, setCount] = useLocalStorage('itemsCount', 0)
    return (
        <>
    
            {children}
        </>
    )
}

export default Layout