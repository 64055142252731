import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route
} from "react-router-dom";
import MainPage from "../pages/MainPage";

function AppRouter(props) {
    return (
        <>
            <Router><Switch>
                <Route exact path="/">
                    <MainPage TagManager={props.TagManager} />
                </Route>
                <Route exact path="/:id">
                    <MainPage TagManager={props.TagManager} />
                </Route>
            </Switch></Router>
        </>
    )
}

export default AppRouter