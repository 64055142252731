import React, { useState, useEffect } from 'react';
import './index.scss';
import Mastercard from '../../../assets/icons/mastercard.svg';
import Visa from '../../../assets/icons/visa.svg';
import Mir from '../../../assets/icons/mir.svg';
import Maestro from '../../../assets/icons/maestro.svg';
import Axios from "axios";
import { API, APIPAYMENT, PRODUCT_ID } from '../../utils/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import AsyncSelectComponent from "../AsyncSelectComponent";
const extendedFields = [
    {
        name: "entrance",
        title: "Подъезд",
    },
    {
        name: "enter_code",
        title: "Домофон",
    },
    {
        name: "floor",
        title: "Этаж",
    },
    {
        name: "suite",
        title: "Квартира/Офис",
    },
];
function CreateAddress(props) {
    const { step, setStep, count, setCount, addressId, user, setAddressId, confirmAddresses, extended, setExtended, setConfirmAddresses, fias, setFias, TagManager } = props
    const [addresses, setAddresses] = useState([])
    const [yandexAddress, setYandexAddress] = useState('');
    const [loader, showLoader] = useState(false);
    const [error, showError] = useState(false);
    const [addressError, showAddressError] = useState(false);

    const getSuggestions = (value) => {
        return value.length >= 3 ? Axios.get('https://geocode-maps.yandex.ru/1.x/'
            , {

                params: {
                    apikey: 'ca1ad69d-fcbb-45e5-ab7e-367be8d0f868',
                    format: 'json',
                    geocode: value
                }
            }).then(res => {
                return res?.data?.response?.GeoObjectCollection?.featureMember.map((el) => {
                    const data = {};
                    if (el.GeoObject.metaDataProperty.GeocoderMetaData.Address.postal_code) {
                        data["zip_code"] = el.GeoObject.metaDataProperty.GeocoderMetaData.Address.postal_code;
                    }
                    Object.values(el.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components).forEach((item) => {
                        data[item.kind] = item.name;
                    })

                    return { value: data, label: el.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted }
                })
            }) : console.log('error')

    }
    const location = window.location

    const addressChange = (value) => {
        setYandexAddress(value?.value)
        if (!value?.value?.house) {
            showAddressError(true);
        } else {
            showAddressError(false)
        }
    }

    useEffect(() => {
        Axios.post(API, {
            method: "customer.addresses.list",
            params: {
                page: 1,
                page_size: 10,
            }
        }).then(res => {
            if (res.data.result) {
                res.data.result?.data?.length > 0 && setAddresses(res.data.result.data)
            }


        })
    }, [])

    const logout = () => {
        return Axios.post(API, {
            method: "customer.logout",
        }).then(res => {
            if (res.data.result) {
                setStep(1)
            }
        })
    }

    const checkAddress = () => {
        if (!fias && !addressError) {
            return yandexAddress ? Axios.post(API, {
                method: "customer.addresses.suggest",
                params: {
                    region: yandexAddress.province,
                    building: yandexAddress.house,
                    city: yandexAddress.locality ? yandexAddress.locality : yandexAddress.province,
                    street: yandexAddress.street
                }
            }).then(res => {
                if (res.data.result.length > 1) {
                    setConfirmAddresses(res.data.result)
                    setStep(3);
                    showError(false)
                } else if (res.data.result.length == 1) {
                    if (res.data.result[0].fias_id == null) {
                        toast.error('Введен некорректный адрес')
                        showError(true)
                        return false;
                    }
                    const params = Object.assign({
                        fias_id: res.data.result[0].fias_id,
                        region: yandexAddress.province,
                        building: yandexAddress.house,
                        city: yandexAddress.locality ? yandexAddress.locality : yandexAddress.province,
                        street: yandexAddress.street,
                        zip_code: yandexAddress.zip_code
                    }, extended)
                    Axios.post(API, {
                        method: "customer.addresses.create",
                        params: params
                    }).then(res => {

                        let id;
                        if (res.data.result) {
                            id = res.data.result.id;

                            setFias(id)
                            setStep(4)
                            TagManager.dataLayer({
                                dataLayer: {
                                    'ecommerce': {
                                        'currencyCode': 'RUB',
                                        'checkout': {
                                            'actionField': { 'step': 3 },
                                            'products': [{
                                                'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                                                'id': '102342',
                                                'price': '1999.00',
                                                'brand': 'Leben',
                                                'category': ' 10217',
                                                'quantity': count,
                                                'position': 1
                                            }]
                                        }
                                    },
                                    'event': 'Enhanced Ecommerce',
                                    'category': 'checkout',
                                    'action': '',
                                    'non-interaction': 'False'
                                }
                            })
                        }

                    })
                }
            }
            ) : console.log('empty')
        } else if (fias && !yandexAddress) {
            setStep(4)
            TagManager.dataLayer({
                dataLayer: {
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'checkout': {
                            'actionField': { 'step': 3 },
                            'products': [{
                                'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                                'id': '102342',
                                'price': '1999.00',
                                'brand': 'Leben',
                                'category': ' 10217',
                                'quantity': count,
                                'position': 1
                            }]
                        }
                    },
                    'event': 'Enhanced Ecommerce',
                    'category': 'checkout',
                    'action': '',
                    'non-interaction': 'False'
                }
            })
        }
    }

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            resolve(getSuggestions(inputValue));
        });

    const handleChange = (value) => {
        setAddressId(JSON.parse(value));
        setFias(JSON.parse(value).value);
        setExtended({
            entrance: '',
            suite: '',
            enter_code: '',
            floor: '',
        })
    }
    return (<div className={`${loader ? 'loader' : ''}`}>
        <div>
            <p className="user">Пользователь: <b>{user.phone}</b></p>
            <div className="counter-wrapper">
                <p><b>Количество товара:</b></p>
                <div className="counter">
                    <button onClick={() => count !== 1 && setCount(count - 1)}>—</button>
                    <span>{count}</span>
                    <button onClick={() => setCount(count + 1)}>+</button>
                </div>
            </div>
            {addresses.length > 0 && <>
                <b className="order__subtitle">Ваши адреса:</b>
                <div className="addresses-list">
                    {addresses.map((el) =>
                        <div className="radio-button radio-button_block">
                            <label
                                className="radio-button__label radio-button__label_with_description"
                                htmlFor={el.id}
                            >
                                <input
                                    type="radio"
                                    className="radio-button__input"
                                    name="adress"
                                    id={el.id}
                                    value={JSON.stringify({ value: el.id, label: el.ext_data.fullAddress })}
                                    checked={addressId.value == el.id ? true : false}
                                    onChange={(e) => handleChange(e.target.value)}
                                />
                                <span className="radio-button__mark" />
                                <p>{el.ext_data.fullAddress}</p>
                            </label>
                        </div>
                    )}
                    <div className="radio-button">
                        <label
                            className="radio-button__label radio-button__label_with_description"
                            htmlFor='default-address'
                        >
                            <input
                                type="radio"
                                className="radio-button__input"
                                name="adress"
                                id='default-address'
                                value={''}
                                checked={addressId == '' ? true : false}
                                onChange={(e) => { setAddressId(''); setFias('') }}
                            />
                            <span className="radio-button__mark" />
                            <p>Выбрать новый адрес</p>
                        </label>
                    </div>
                </div>
            </>}

            {((addresses.length > 0 && addresses.filter((el) => el.id == addressId.value).length == 0) || (addresses.length == 0)) &&
                <><div className="select-block" >
                    <AsyncSelectComponent
                        placeholder="Введите адрес"
                        promiseOptions={promiseOptions}
                        handleChange={addressChange}
                    />
                    {error && <div className="select-block__error">
                        Введите корректный адрес
                </div>}
                    {addressError && <div className="select-block__error">
                        Введен некорректный адрес, заполните следующие данные: {!yandexAddress?.house && "Номер дома"}
                    </div>}

                </div>

                    <div className="inputs">
                        {extendedFields.map((el) => {
                            return <div className="form-group" style={{ marginTop: '20px', width: 'calc(50% - 5px)' }}>
                                <input
                                    type="text"
                                    value={extended[el.name]}
                                    onChange={e => {
                                        setExtended({
                                            ...extended, [el.name]: e.target.value
                                        });
                                    }}
                                    name={el.name}
                                />
                                <label htmlFor="">{el.title}</label>
                            </div>
                        })}
                    </div>
                </>

            }
        </div>
        <div className="steps">
            <div>
                {step !== 1 &&
                    <button className="btn-prev" onClick={() => logout()}>Сменить пользователя</button>
                }
            </div>
            <div>
                {step == 2 && <button onClick={() => checkAddress()}>Оформить заказ</button>}
            </div>
        </div>
    </div >
    )
}

export default CreateAddress