import React, { useState } from "react";
import './mainpage.scss';
import Layout from "../layouts/Layout"
import Params1 from '../../assets/icons/param1.svg';
import Params2 from '../../assets/icons/param2.svg';
import Params3 from '../../assets/icons/param3.svg';
import Params4 from '../../assets/icons/param4.svg';
import Params5 from '../../assets/icons/param5.svg';
import Params6 from '../../assets/icons/param6.svg';
import View from '../../assets/icons/view.svg';
import Socials from "../components/Socials";
import Modal from 'react-modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import Header from "../layouts/Header";
import useLocalStorage from "../utils/useLocalStorage"
import ModalSuccess from "../components/ModalSuccess";
import ModalError from "../components/ModalError";
import { withRouter } from "react-router";
import Countdown from "react-countdown";

function MainPage(props) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const { match, TagManager } = props;
    const [orderShow, setOrderShow] = useState(false);
    const [successIsOpen, setSuccessIsOpen] = useState(match.params.id == 'success' ? true : false);
    const [errorIsOpen, setErrorIsOpen] = useState(match.params.id == 'fail' ? true : false);
    const [paramsModalIsOpen, setParamsIsOpen] = useState(false);
    const [count, setCount] = useLocalStorage('itemsCount', 0)
    let today = new Date();
    today.setDate(today.getDate() + 3)
    today.setHours(today.getHours() - today.getHours())
    today.setMinutes(today.getMinutes() - today.getMinutes())

    const renderer = ({ days, hours, minutes, seconds }) => {
        return <span className="countdown__date">{days} д. {hours} ч. {minutes} м.</span>;
    };
    SwiperCore.use([Navigation, Pagination, EffectFade]);
    const pushDataLayer = (count) => {
        TagManager.dataLayer({
            dataLayer: {
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'add': {
                        'products': [{
                            'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                            'id': '102342',
                            'price': '1999.00',
                            'brand': 'Leben',
                            'category': '10217',
                            'quantity': count,
                            'position': 1
                        }]
                    }
                },
                'event': 'Enhanced Ecommerce',
                'category': 'add',
                'action': '',
                'non-interaction': 'False'
            }
        })

        console.log('dataLayer', TagManager)
    }

    const showOrderBlock = (count) => {
        setOrderShow(true);
        TagManager.dataLayer({
            dataLayer: {
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'checkout': {
                        'actionField': { 'step': 1 },
                        'products': [{
                            'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                            'id': '102342',
                            'price': '1999.00',
                            'brand': 'Leben',
                            'category': ' 10217',
                            'quantity': count,
                            'position': 1
                        }]
                    }
                },
                'event': 'Enhanced Ecommerce',
                'category': 'checkout',
                'action': '',
                'non-interaction': 'False'
            }
        })
    }
    return (
        <>
            <Header count={count} setCount={setCount} orderShow={orderShow} setOrderShow={setOrderShow} TagManager={TagManager} />
            <main>
                <div className="container">
                    <div className="product">
                        <div className="product__info">
                            <h1>Чайник Leben</h1>
                            <div className="product__params">
                                <div className="product__params-item">
                                    <img src={Params1} alt="" />

                                    <div>
                                        <b>1,7</b>
                                        <span>Литра</span>
                                    </div>
                                </div>
                                <div className="product__params-item">
                                    <img src={Params3} al5="" />

                                    <div>
                                        <b>1850</b>
                                        <span>Ватт</span>
                                    </div>
                                </div>
                                <div className="product__params-item">
                                    <img src={Params2} alt="" />

                                    <div>
                                        <b>Стекло</b>
                                        <span>материал</span>
                                    </div>
                                </div>

                                <div className="product__params-item">
                                    <img src={Params4} alt="" />
                                    <div>
                                        <b>LED</b>
                                        <span>подсветка</span>
                                    </div>
                                </div>
                                <div className="product__params-item">
                                    <img src={Params5} alt="" />
                                    <div>
                                        <b>Нагрев</b>
                                        <span>Выбор температуры</span>
                                    </div>
                                </div>
                                <div className="product__params-item">
                                    <img src={Params6} alt="" />
                                    <div>
                                        <b>Фильтр</b>
                                        <span>Дехлорирование воды</span>
                                    </div>
                                </div>
                            </div>
                            <div className="product__extended" onClick={() => setParamsIsOpen(true)}>Подробная информация</div>
                            <div className="product__footer">

                                <button onClick={() => { count == 0 && setCount(1); setOrderShow(true); pushDataLayer(count) }}>Купить сейчас</button>
                                {/* {count > 0 && (
                                    <div className="counter">
                                        <button onClick={() => count !== 0 && setCount(count - 1)}>—</button>
                                        <span>{count}</span>
                                        <button onClick={() => {
                                            setCount(count + 1);
                                            pushDataLayer(count + 1);
                                        }}>+</button>
                                    </div>
                                )} */}
                                <div><span>Цена: <b>2999₽</b></span>
                                    <h2>1999₽</h2>
                                </div>
                            </div>
                            <p className="countdown"> До конца акции осталось: <Countdown date={today} renderer={renderer} /></p>
                        </div>
                        <div className="product__banner">
                            <img src="public/images/product.png" alt="Чайник Leben" />
                            <button className="product__view" onClick={() => setIsOpen(true)}>
                                <img src={View} alt="Показать галерею" />
                            </button>
                        </div>
                    </div>

                </div>
            </main>
            <div className="circle">

            </div>
            <div className="product__button">
                <div className="container">
                    <button className="product__view" onClick={() => setIsOpen(true)}>
                        <img src={View} alt="Показать галерею" />
                    </button>

                </div>
                <div className="woman-block">
                    <img className="woman woman_left" src="public/images/womanLeft.png" alt="Хочу!" onClick={() => {
                        if (!count > 0) {
                            setCount(count + 1);
                            pushDataLayer(count + 1);
                        }
                        showOrderBlock(count)
                    }} />
                    <img className="woman woman_right" src="public/images/womanRight.png" alt="Галочка, чайник Leben за 1999₽"
                        onClick={() => {
                            if (!count > 0) {
                                setCount(count + 1);
                                pushDataLayer(count + 1);
                            }
                            showOrderBlock(count)
                        }}
                    />
                </div>
            </div>
            <footer className="footer">
                <div className="container">
                    <div className="footer__inner">
                        <span>Найдите нас в </span>
                        <Socials />
                    </div>
                    <div className="footer__doc">
                        <a href="public/images/leben_doc1.pdf" target="_blank">Условия обработки персональных данных</a> <br /> <a href="public/images/leben_doc2.pdf" target="_blank">Пользовательское соглашение</a>
                    </div>
                </div>
            </footer>
            <div className="mobile-circle">
                <img className="woman woman_left" src="public/images/womanLeft.png" alt="Хочу!" onClick={() => {
                    if (!count > 0) {
                        setCount(count + 1);
                        pushDataLayer(count + 1);
                    }
                    showOrderBlock(count)
                }} />
                <img className="woman woman_right" src="public/images/womanRight.png" alt="Галочка, чайник Leben за 1999₽" onClick={() => {
                    if (!count > 0) {
                        setCount(count + 1);
                        pushDataLayer(count + 1);
                    }
                    showOrderBlock(count)
                }} />
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                contentLabel="Carousel Modal"
                className="carousel-modal"
                style={{ zIndex: 999999 }}
            >
                <div className="close-wrapper">
                    <button className="modal-close" onClick={() => setIsOpen(false)}>
                        <span></span><span></span>
                    </button>
                </div>

                <Swiper
                    effect="fade"
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((el) => {
                        return <SwiperSlide><div className="slide-content"><img src={`public/images/photo${el}.jpg`} alt="Чайник Leben" /></div></SwiperSlide>
                    })}
                </Swiper>
            </Modal>
            <Modal
                isOpen={paramsModalIsOpen}
                onRequestClose={() => setParamsIsOpen(false)}
                contentLabel="Carousel Modal"
                style={{ zIndex: 999999 }}
                className="params-modal"
            >
                <button className="modal-close" onClick={() => setParamsIsOpen(false)}>
                    <span></span><span></span>
                </button>

                <div className="modal-info">
                    <h5 style={{ textTransform: 'uppercase' }}>LEBEN Чайник 1,7 л электрический с поддержанием температуры</h5>
                    <ul>
                        <li>Электрический чайник  LEBEN удивит вас своими возможностями. </li>
                        <li>Объем 1,7 л  подойдет для большой компании. </li>
                        <li>Благодаря корпусу из стекла и LED-подсветки чайник добавит яркий акцент. </li>
                        <li>Поворот корпуса 360 градусов  и легкость в управлении делает использование ещё более комфортным </li>
                        <li>Больше не нужно отвлекаться во время беседы за чашкой чая и кипятить воду повторно,  благодаря функции выбора и поддержания заданной температуры. </li>
                        <li>Эргономичный дизайн никого не оставит равнодушным и легко впишется в интерьер вашей кухни. </li>
                        <li>Оптимальное сочетание функциональности, цены и качества! </li>
                    </ul>
                    <div><b>Все это LEBEN!</b></div>
                    <div className="modal-pluses">
                        <p>Главные преимущества</p>
                        <div className="modal-pluses__content">
                            <div>Функция дехлорирования воды</div>
                            <div>Функция выбора и поддержания заданной температуры</div>
                            <div>Защита от включения без воды</div>
                        </div>
                    </div>
                </div>

            </Modal>
            { successIsOpen && <ModalSuccess TagManager={TagManager} show={successIsOpen} setShow={setSuccessIsOpen} count={count} setCount={setCount} />}
            { errorIsOpen && <ModalError show={errorIsOpen} setShow={setErrorIsOpen} />}
        </>
    )
}

export default withRouter(MainPage)