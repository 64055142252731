import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Icon from "../../../assets/icons/check-mark.svg"
import { useHistory } from "react-router-dom";

function ModalSuccess(props) {
    const { show, setShow, count, setCount, TagManager } = props
    const history = useHistory();

    useEffect(() => {
        TagManager.dataLayer({ dataLayer: ({
             'ecommerce': {
               'currencyCode': 'RUB',
                   'purchase': {
                    'actionField': {
                       'id' : 'T12', // идентификатор покупки
                      'revenue' : '1999.00', // общая сумма покупки
                    },
            
                 'products': [{  
                    'name' : 'LEBEN Чайник электрический 1,7л, 1850Вт',
                   'id' : '102342',
                   'price' : '1999',
                   'brand' : 'Leben',
                   'category' : '10217',
                   'quantity' : count, // Количество
                   'position' : 1  // Позиция в списке (целое число)
                  }]    
               }
             },
               'event': 'Enhanced Ecommerce',
               'category': 'purchase',
               'action': '',
               'non-interaction': 'False'
            })})
        setCount(0);
        
    }, [])
    return (<>
        <Modal
            isOpen={show}
            onRequestClose={() => {
                history.push(`/`)
                setShow(false)
            }}
            style={{ zIndex: 999999 }}
            className="status-modal"
        >
            <button className="modal-close" onClick={() => {
                setShow(false);
                history.push(`/`)
            }}>
                <span></span><span></span>
            </button>
            <div className="status">
                <img src={Icon} alt="" />
                <h4>Успешно!</h4>
                <p>Оплата прошла успешно, Ваш номер заказа: №123123</p>
            </div>
        </Modal>
    </>)
}

export default ModalSuccess