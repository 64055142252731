import React from 'react';

function Socials() {
    return (
        <div className="socials">
            <a href="https://vk.com/leben_home" target="_blank">
                <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.4352 0.991294C23.6132 0.432101 23.4352 0.0254174 22.6473 0.0254174H20.0292C19.3684 0.0254174 19.0634 0.381267 18.8854 0.762535C18.8854 0.762535 17.5383 4.01601 15.6574 6.12569C15.0474 6.73572 14.7678 6.93907 14.4373 6.93907C14.2594 6.93907 14.0306 6.73572 14.0306 6.17653V0.965876C14.0306 0.305013 13.8273 0 13.2681 0H9.15042C8.74373 0 8.48956 0.305012 8.48956 0.610026C8.48956 1.24547 9.43001 1.39798 9.53168 3.17723V7.04074C9.53168 7.87953 9.37918 8.03203 9.04875 8.03203C8.15912 8.03203 5.99861 4.75313 4.7023 1.01671C4.44812 0.279596 4.19394 0 3.53308 0H0.889624C0.127089 0 0 0.355848 0 0.737115C0 1.4234 0.889625 4.88022 4.14311 9.45543C6.30362 12.5818 9.37918 14.2594 12.1497 14.2594C13.8273 14.2594 14.0306 13.8781 14.0306 13.2427V10.8788C14.0306 10.1163 14.1831 9.98921 14.7169 9.98921C15.0982 9.98921 15.7845 10.1925 17.335 11.6922C19.1142 13.4714 19.4192 14.2848 20.4105 14.2848H23.0286C23.7911 14.2848 24.1469 13.9036 23.9436 13.1664C23.7148 12.4293 22.8506 11.3618 21.7322 10.0909C21.1222 9.37918 20.2072 8.59123 19.9276 8.20996C19.5463 7.7016 19.648 7.49826 19.9276 7.04074C19.9022 7.04074 23.1048 2.51636 23.4352 0.991294Z" fill="#A7B8C2" />
                </svg>
            </a>
            <a href="https://ok.ru/group60013160169551" target="_blank">
                <svg id="Bold" fill="#A7B8C2" enable-background="new 0 0 24 24" viewBox="0 0 24 24" width="28" height="20" xmlns="http://www.w3.org/2000/svg"><path d="m4.721 12.881c-.613 1.205.083 1.781 1.671 2.765 1.35.834 3.215 1.139 4.413 1.261-.491.472 1.759-1.692-4.721 4.541-1.374 1.317.838 3.43 2.211 2.141l3.717-3.585c1.423 1.369 2.787 2.681 3.717 3.59 1.374 1.294 3.585-.801 2.226-2.141-.102-.097-5.037-4.831-4.736-4.541 1.213-.122 3.05-.445 4.384-1.261l-.001-.001c1.588-.989 2.284-1.564 1.68-2.769-.365-.684-1.349-1.256-2.659-.267 0 0-1.769 1.355-4.622 1.355-2.854 0-4.622-1.355-4.622-1.355-1.309-.994-2.297-.417-2.658.267z" /><path d="m11.999 12.142c3.478 0 6.318-2.718 6.318-6.064 0-3.36-2.84-6.078-6.318-6.078-3.479 0-6.319 2.718-6.319 6.078 0 3.346 2.84 6.064 6.319 6.064zm0-9.063c1.709 0 3.103 1.341 3.103 2.999 0 1.644-1.394 2.985-3.103 2.985s-3.103-1.341-3.103-2.985c-.001-1.659 1.393-2.999 3.103-2.999z" /></svg>
            </a>
            <a href="https://instagram.com/leben.home?utm_medium=copy_link" target="_blank">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path d="M13.9999 17.3346C15.8416 17.3346 17.3346 15.8417 17.3346 14C17.3346 12.1583 15.8416 10.6653 13.9999 10.6653C12.1583 10.6653 10.6653 12.1583 10.6653 14C10.6653 15.8417 12.1583 17.3346 13.9999 17.3346Z" fill="#A7B8C2" />
                        <path d="M23.9405 9.87932C23.8928 8.8154 23.7221 8.08892 23.476 7.44978C23.2219 6.79079 22.8766 6.23501 22.3208 5.67924C21.765 5.12346 21.2053 4.78206 20.5502 4.52402C19.9151 4.27789 19.1886 4.10719 18.1207 4.05955C17.0568 4.01191 16.7154 4 14 4C11.2846 4 10.9432 4.01191 9.87536 4.05955C8.81144 4.10719 8.08496 4.27789 7.44979 4.52402C6.79079 4.77809 6.23502 5.12346 5.67924 5.67924C5.12346 6.23501 4.78206 6.79476 4.52402 7.44978C4.27789 8.08495 4.10719 8.81143 4.05955 9.87932C4.01191 10.9432 4 11.2846 4 14C4 16.7154 4.01191 17.0568 4.05955 18.1247C4.10719 19.1886 4.27789 19.915 4.52402 20.5542C4.77809 21.2132 5.12346 21.769 5.67924 22.3247C6.23502 22.8805 6.79476 23.2219 7.44979 23.48C8.08496 23.7261 8.81144 23.8968 9.87933 23.9444C10.9472 23.9921 11.2846 24.004 14.004 24.004C16.7233 24.004 17.0608 23.9921 18.1286 23.9444C19.1926 23.8968 19.919 23.7261 20.5582 23.48C21.2172 23.2259 21.773 22.8805 22.3287 22.3247C22.8845 21.769 23.2259 21.2092 23.4839 20.5542C23.7301 19.919 23.9008 19.1925 23.9484 18.1247C23.9961 17.0568 24.008 16.7193 24.008 14C24.008 11.2807 23.9921 10.9432 23.9405 9.87932ZM14 19.137C11.1656 19.137 8.86305 16.8384 8.86305 14C8.86305 11.1655 11.1616 8.86304 14 8.86304C16.8384 8.86304 19.137 11.1616 19.137 14C19.137 16.8384 16.8384 19.137 14 19.137ZM19.3394 9.86344C18.6765 9.86344 18.1406 9.32751 18.1406 8.66455C18.1406 8.00159 18.6765 7.46566 19.3394 7.46566C20.0024 7.46566 20.5383 8.00159 20.5383 8.66455C20.5383 9.32751 20.0024 9.86344 19.3394 9.86344Z" fill="#A7B8C2" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="20" height="20" fill="white" transform="translate(4 4)" />
                        </clipPath>
                    </defs>
                </svg>

            </a>
            <a href="https://www.youtube.com/channel/UC7UwVoSMfZ9LV-CFSctCLdQ" target="_blank" style={{ marginLeft: '0px' }}>
                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 6.443a42.671 42.671 0 016-.443c2.476 0 4.667.25 6 .443a2.552 2.552 0 012.154 2.055c.175.932.346 2.294.346 4.002 0 1.704-.17 3.065-.341 4.003a2.562 2.562 0 01-2.159 2.054h-.005A42.833 42.833 0 0112.5 19c-2.462 0-4.663-.25-6-.443a2.568 2.568 0 01-2.158-2.054A22.373 22.373 0 014 12.5c0-1.708.17-3.07.342-4.002A2.562 2.562 0 016.5 6.443zm9.643 6.057l-6.072 2.954V9.546l6.072 2.955z" fill="#a7b8c2"></path></svg>

            </a>
        </div>
    )
}

export default Socials