import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './index.scss';
import Axios from "axios";
import { API } from '../../utils/api'
import useCountDown from 'react-countdown-hook';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateOrder from './createOrder';
import CreateAddress from './createAddress';
import ConfirmAddress from './confirmAddress';
import Countdown from "react-countdown";

function Order(props) {
    const { show, setShow, count, setCount, TagManager } = props
    const [step, setStep] = useState(1);
    const [login, setLogin] = useState('');
    const [code, setCode] = useState('');
    const [timeLeft, { start, pause, resume, reset }] = useCountDown(180000, 1000);
    const [confirmAddresses, setConfirmAddresses] = useState([]);
    const [addressId, setAddressId] = useState('');
    const [fias, setFias] = useState('')
    const [user, setUser] = useState({})
    const [extended, setExtended] = useState({
        entrance: '',
        enter_code: '',
        floor: '',
        suite: '',
    })

    useEffect(() => {
        Axios.post(API, {
            method: "customer.get",
        }).then(res => {
            console.log(res.data)
            if (res.data.result) {
                setStep(2);
                setUser(res.data.result)
            }
        })
    }, [])

    const resetCallback = () => {
        setFias('');
        setAddressId('');
    }
    const getCode = () => {
        return login.length > 0 ? Axios.post(API, {
            method: "customer.login",
            params: {
                login: login,
            }
        })
            .then(res => {

                if (res.data.result) {
                    const result = res.data.result
                    if (result.verification_info.new_code_sent) {
                        toast.success("Код выслан");
                        start(result.verification_info.data.next_code_after * 1000)
                    } else {
                        toast.error("Произошла ошибка");
                    }


                } else {
                    toast.error("Произошла ошибка");
                }

            }).catch(error => {
                console.log(error)
                // toast.error(error.response.data.message);
            }) : toast.info("Заполните поле 'E-mail / Номер телефона'")
    }

    const auth = () => {
        return code.length > 0 ? Axios.post(API, {
            method: "customer.verify",
            params: {
                code: code
            }
        })
            .then(res => {
                if (res.data) {
                    console.log(res.data.result);
                    if (res.data.result.verified) {
                        setStep(2)
                        TagManager.dataLayer({
                            dataLayer: {
                                'ecommerce': {
                                    'currencyCode': 'RUB',
                                    'checkout': {
                                        'actionField': { 'step': 2 },
                                        'products': [{
                                            'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                                            'id': '102342',
                                            'price': '1999.00',
                                            'brand': 'Leben',
                                            'category': ' 10217',
                                            'quantity': count,
                                            'position': 1
                                        }]
                                    }
                                },
                                'event': 'Enhanced Ecommerce',
                                'category': 'checkout',
                                'action': '',
                                'non-interaction': 'False'
                            }
                        })
                    } else {
                        toast.error("Неверные данные для авторизации");

                    }
                } else {
                    // toast.error(res.data.message);
                }

            }).catch(error => {
                console.log(error)
            }) : toast.info("Заполните необходимые поля")
    }
    return (<>
        <Modal
            isOpen={show}
            onRequestClose={() => setShow(false)}
            style={{ zIndex: 999999 }}
            className="order-modal"
        >

            <div className="order">
                <button className="modal-close" onClick={() => setShow(false)}>
                    <span></span><span></span>
                </button>
                <h6>Оформление заказа</h6>
                {step == 1 && <>
                    <div>
                        <div className="form-group">
                            <input type="text" value={login} onChange={e => setLogin(e.target.value)} />
                            <label htmlFor="">E-mail / Номер телефона</label>

                            {timeLeft == 0 && <button onClick={() => getCode()}>Получить код</button>}
                        </div>
                        <div className="countdown">
                            {timeLeft !== 0 && <>Повторная отправка кода возможна через {timeLeft / 1000} сек.</>}
                        </div>
                        <div className="form-group" style={{ marginTop: '20px' }}>
                            <input type="text" value={code} onChange={e => setCode(e.target.value)} />
                            <label htmlFor="">Код подтверждения</label>
                        </div>
                    </div>
                    <div className="steps">
                        <div>
                            {step !== 1 &&
                                <button className="btn-prev" onClick={() => setStep(step - 1)}>Назад</button>
                            }
                        </div>
                        <div>
                            {step !== 2 &&
                                <button onClick={() => {
                                    auth();
                                }}>
                                    Продолжить</button>
                            }
                            {step == 2 && <button>Оформить заказ</button>}
                        </div>
                    </div>
                </>}
                {step == 2 && <>
                    <CreateAddress
                        setCount={setCount}
                        step={step}
                        setStep={setStep}
                        count={count}
                        extended={extended}
                        setExtended={setExtended}
                        confirmAddresses={confirmAddresses}
                        setConfirmAddresses={setConfirmAddresses}
                        addressId={addressId}
                        setAddressId={setAddressId}
                        fias={fias}
                        setFias={setFias}
                        user={user}
                        TagManager={TagManager}
                    />
                </>
                }
                {step == 3 && <>
                    <ConfirmAddress
                        step={step}
                        setStep={setStep}
                        count={count}
                        extended={extended}
                        setExtended={setExtended}
                        confirmAddresses={confirmAddresses}
                        setConfirmAddresses={setConfirmAddresses}
                        addressId={addressId}
                        setAddressId={setAddressId}
                        fias={fias}
                        setFias={setFias}
                        resetCallback={resetCallback}
                    />
                </>}
                {step == 4 && <>
                    <CreateOrder
                        step={step}
                        setStep={setStep}
                        count={count}
                        confirmAddresses={confirmAddresses}
                        setConfirmAddresses={setConfirmAddresses}
                        addressId={addressId}
                        setAddressId={setAddressId}
                        fias={fias}
                        setFias={setFias}
                        resetCallback={resetCallback}
                        TagManager={TagManager}
                    />
                </>}
            </div>
        </Modal>
        <ToastContainer />
    </>)
}

export default Order