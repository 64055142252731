import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useHistory } from "react-router-dom";
import Icon from "../../../assets/icons/exclamation.svg"
function ModalError(props) {
    const { show, setShow } = props
    const history = useHistory();

    return (<>
        <Modal
            isOpen={show}
            onRequestClose={() => {
                history.push(`/`)
                setShow(false)
            }}
            style={{ zIndex: 999999 }}
            className="status-modal"
        >
            <button className="modal-close" onClick={() => {
                setShow(false);
                history.push(`/`)
            }}>
                <span></span><span></span>
            </button>
            <div className="status">
                <img src={Icon} alt="" />
                <h4>Произошла ошибка</h4>
                <p>Оплата прошла успешно, Ваш номер заказа: №123123</p>
            </div>
        </Modal>
    </>)
}

export default ModalError