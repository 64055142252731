import { useState, useEffect, useRef } from "react";
import './index.scss'
import Logo from '../../../assets/icons/logo.svg';
import Logo2 from '../../../assets/icons/logo_mobile.svg';
import Bucket from '../../../assets/icons/bucket.svg';
import Socials from "../../components/Socials";
import useOnClickOutside from "../../utils/clickOutside";
import Order from '../../components/Order';

function Header(props) {
    const { count, setCount, orderShow, setOrderShow, TagManager } = props;
    const [show, setShow] = useState(false)
    const ref = useRef();
    useOnClickOutside(ref, () => setShow(false));
    const showOrderBlock = (count) => {
        setOrderShow(true);
        TagManager.dataLayer({
            dataLayer: {
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'checkout': {
                        'actionField': { 'step': 1 },
                        'products': [{
                            'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                            'id': '102342',
                            'price': '1999.00',
                            'brand': 'Leben',
                            'category': ' 10217',
                            'quantity': count,
                            'position': 1
                        }]
                    }
                },
                'event': 'Enhanced Ecommerce',
                'category': 'checkout',
                'action': '',
                'non-interaction': 'False'
            }
        })
    }
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header__inner">
                        <img className="header__logo header__logo_desktop" src={Logo} alt="LEBEN, Просто для жизни" />
                        <img className="header__logo header__logo_mobile" src={Logo2} alt="LEBEN, Просто для жизни" />
                        <div className="header__end" >
                            <Socials />
                            <div ref={ref}>
                                <button className="header__bucket" onClick={(event) => { count > 0 ? showOrderBlock(count) : setShow(!show) }}>
                                    <span>Корзина</span>
                                    <img src={Bucket} alt="" />

                                    {count !== 0 && <i className="header__bucket-counter">{count}</i>}
                                </button>
                                <div className={`bucket__preview ${show ? 'active' : ''}`}>
                                    <div className="bucket__preview-item">
                                        <img src="public/images/product.png" alt="Чайник Leben" />
                                        <span className="bucket__preview-name">Чайник Leben</span>
                                        <div className="counter">
                                            <button onClick={() => count !== 0 && setCount(count - 1)}>—</button>
                                            <span>{count}</span>
                                            <button onClick={() => setCount(count + 1)}>+</button>

                                        </div>
                                        <b>{count * 1999}₽</b>
                                    </div>
                                    {count > 0 && <p onClick={() => { showOrderBlock(count); setShow(false) }}>Перейти к оформлению</p>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </header>
            {orderShow && <Order show={orderShow} setShow={setOrderShow} count={count} setCount={setCount}  TagManager={TagManager} />}
        </>
    )
}

export default Header
