import React, { useState, useEffect } from 'react';
import './index.scss';
import Mastercard from '../../../assets/icons/mastercard.svg';
import Visa from '../../../assets/icons/visa.svg';
import Mir from '../../../assets/icons/mir.svg';
import Maestro from '../../../assets/icons/maestro.svg';
import Axios from "axios";
import { API, APIPAYMENT, PRODUCT_ID } from '../../utils/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

function CreateOrder(props) {
    const { step, setStep, count, addressId, fias, resetCallback, TagManager } = props
    const [suggestions, setSuggestions] = useState([]);
    const [dates, setDates] = useState([]);
    const [deliveryId, setDeliveryId] = useState('');
    const [haveDeliveries, setHaveDeliveries] = useState(false)
    const [loader, showLoader] = useState(false);
    const [error, showError] = useState(false);
    const [mail, setMail] = useState('')
    const [name, setName] = useState('')
    const [checked, setChecked] = useState(true);
    const [checkedError, showCheckedError] = useState(false)
    const location = window.location
    const siteUrl = `${location.protocol}//${location.host}`

    useEffect(() => {
        getDeliveries()
    }, [fias])

    // setAddressId(id)
    // getDeliveries(id)
    const getDeliveries = () => {
        showLoader(true)
        Axios.post(API, {
            method: "customer.orders.couriers.list",
            params: {
                address_id: fias ? fias : addressId.value,
                items: [
                    {
                        id: PRODUCT_ID,
                        quantity: count
                    }
                ]
            }
        }).then(res => {
            showLoader(false);
            if (res.data?.result?.length > 0) {
                const datesArray = res.data.result.map((el) => {
                    const date = new Date(el.date);
                    const days = date.getDate();
                    const month = (+date.getMonth() + 1).toString().length > 1 ? (+date.getMonth() + 1) : "0" + (+date.getMonth() + 1);
                    const year = date.getFullYear();
                    const newDate = `${days}.${month}.${year}`;

                    return { value: el.id, label: `${newDate} с ${el.from} до ${el.to}` }
                })
                setDates(datesArray)
                setHaveDeliveries(true)
            } else {
                setHaveDeliveries(true)
            }

        }).catch(error => {
            showLoader(false);
        })
    }

    const submitOrder = () => {
        const id = fias ? fias : addressId.value;
        const params = {
            address_id: id,
            delivery_id: deliveryId,
            items: [
                {
                    id: PRODUCT_ID,
                    quantity: count
                }
            ],
            delivery_type: "COURIER",
            payment_type: 'prepaid',
            client_type: 'leben'
        }
        if (!checked) {
            showCheckedError(true)
            return false
        } else {
            showCheckedError(false)
        }
        if (!mail || !name) {
            showError(true)
            return false
        }

        params['first_name'] = name;
        params['email'] = mail;
        id && deliveryId && showLoader(true)
        return id && deliveryId ?
            Axios.post(API, {
                method: "customer.orders.create",
                params: params
            }).then(res => {
                showError(false)
                showLoader(false)
                TagManager.dataLayer({
                    dataLayer: {
                        'ecommerce': {
                            'currencyCode': 'RUB',
                            'checkout': {
                                'actionField': { 'step': 5 },
                                'products': [{
                                    'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                                    'id': '102342',
                                    'price': '1999.00',
                                    'brand': 'Leben',
                                    'category': ' 10217',
                                    'quantity': count,
                                    'position': 1
                                }]
                            }
                        },
                        'event': 'Enhanced Ecommerce',
                        'category': 'checkout',
                        'action': '',
                        'non-interaction': 'False'
                    }
                })
                if (res.data) {
                    window.location = `https://cms.digitech.ru/payment?payment_id=${res.data.result.payments[0].id}&success_url=${siteUrl}/success&fail_url=${siteUrl}/fail`
                }
            })
            :
            showError(true)
    }
    return (<div className={`${loader ? 'loader' : ''}`}>
        <div>

            {fias && dates.length > 0 && haveDeliveries && (
                <div className="select-block" style={{ marginTop: '15px' }}>
                    <Select
                        className="date-select"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="dates"
                        onChange={(value) => {
                            setDeliveryId(value.value);
                            TagManager.dataLayer({
                                dataLayer: {
                                    'ecommerce': {
                                        'currencyCode': 'RUB',
                                        'checkout': {
                                            'actionField': { 'step': 4 },
                                            'products': [{
                                                'name': 'LEBEN Чайник электрический 1,7л, 1850Вт',
                                                'id': '102342',
                                                'price': '1999.00',
                                                'brand': 'Leben',
                                                'category': ' 10217',
                                                'quantity': count,
                                                'position': 1
                                            }]
                                        }
                                    },
                                    'event': 'Enhanced Ecommerce',
                                    'category': 'checkout',
                                    'action': '',
                                    'non-interaction': 'False'
                                }
                            })
                        }}
                        placeholder="Выберите дату получения заказа"
                        options={dates}
                    />
                    {error && deliveryId.length == 0 && <div className="select-block__error">
                        Заполните поле
                </div>}
                </div>
            )}
            {fias && dates.length == 0 && haveDeliveries && (
                <div style={{ marginBottom: '25px', color: "#999" }}>По данному адресу нет доступных доставок</div>
            )}

            {deliveryId && (<>
                <div className="select-block">
                    <div className="form-group" style={{ marginTop: '30px' }}>
                        <input type="text" value={name} onChange={e => setName(e.target.value)} />
                        <label htmlFor="">Имя</label>
                    </div>
                    {error && name.length == 0 && <div className="select-block__error">
                        Заполните поле
                    </div>}
                </div>
                <div className="select-block" style={{ marginBottom: '30px' }} >
                    <div className="form-group" style={{ marginTop: '30px' }}>
                        <input type="text" value={mail} onChange={e => setMail(e.target.value)} />
                        <label htmlFor="">E-mail для получения чека</label>
                    </div>
                    {error && mail.length == 0 && <div className="select-block__error">
                        Заполните поле
                    </div>}
                </div>
                <b className="order__subtitle">Оплата:</b>
                <div className="radio-button">
                    <label
                        className="radio-button__label radio-button__label_with_description"
                        htmlFor="payment"
                    >
                        <input
                            type="radio"
                            className="radio-button__input"
                            id="payment"
                            value="true"
                            checked="true"
                        />
                        <span className="radio-button__mark" />
                        <p>Оплата картой</p>
                        <div>
                            <img src={Mastercard} alt="" />
                            <img src={Visa} alt="" />
                            <img src={Mir} alt="" />
                            <img src={Maestro} alt="" />
                        </div>
                    </label>
                </div>
                <div className="order__total">
                    <p>Количество товаров: {count}</p>
                    <p>Итого: <b>{1999 * count}₽</b></p>
                </div>
            </>)}
        </div>
        {deliveryId && <div className={`checkbox-button ${checkedError ? 'error' : ''}`}>
            <label
                className="checkbox-button__label checkbox-button__label_with_description"
                htmlFor="politika"
            >
                <input
                    type="checkbox"
                    className="checkbox-button__input"
                    id="politika"
                    value="true"
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                />
                <span className="checkbox-button__mark" />
                <p>Я ознакомлен и согласен с <a href="public/images/leben_doc1.pdf" target="_blank">условиями обработки персональных данных</a> и <a href="public/images/leben_doc2.pdf" target="_blank">пользовательским соглашением</a></p>
            </label>
        </div>
        }
        <div className="steps">
            <div>
                {step !== 1 &&
                    <button
                        className="btn-prev"
                        onClick={() => {
                            setStep(2);
                            resetCallback();
                        }}
                    >
                        Назад
                    </button>
                }
            </div>
            <div>

                {step == 4 && deliveryId && <button onClick={() => submitOrder()}>Оформить заказ</button>}
            </div>
        </div>
    </div >
    )
}

export default CreateOrder