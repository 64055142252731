import React from "react";
import AppRouter from './jsx/core/router'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-MJ6PVKH',
}

TagManager.initialize(tagManagerArgs)

function App() {
   
    return ( <div className="page">
        <AppRouter TagManager={TagManager} />
        </div>
    );
}

export default App;