import React, { useState, useEffect } from 'react';
import './index.scss';
import Axios from "axios";
import { API, APIPAYMENT, PRODUCT_ID } from '../../utils/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

function ConfirmAddress(props) {
    const { step, setStep, addressId, resetCallback, extended, setExtended, setAddressId, confirmAddresses, fias, setFias } = props
    const [loader, showLoader] = useState(false);

    const handleChange = (value) => {
        setAddressId(JSON.parse(value));
        setFias(JSON.parse(value).value)

    }
    const checkAddress = () => {
        if (fias) {
            const params = Object.assign({
                fias_id: addressId.value,
                building: " ",
                city: addressId.label,
            }, extended)
            return Axios.post(API, {
                method: "customer.addresses.create",
                params: params
            }).then(res => {
                let id;
                if (res.data.result) {
                    id = res.data.result.id;

                    setFias(id)
                    console.log('iddd', id)
                    setStep(4);
                }

            })
        } else {
            toast.info('Выберите адрес')
        }
    }

    return (<div className={`${loader ? 'loader' : ''}`}>
        <div>
            {confirmAddresses?.length > 0 && <>
                <b className="order__subtitle">Уточнение адреса:</b>
                <div className="addresses-list">
                    {confirmAddresses.map((el) =>
                        el.fias_id && <div className="radio-button radio-button_block">
                            <label
                                className="radio-button__label radio-button__label_with_description"
                                htmlFor={el.fias_id}
                            >
                                <input
                                    type="radio"
                                    className="radio-button__input"
                                    name="adress"
                                    id={el.fias_id}
                                    value={JSON.stringify({ value: el.fias_id, label: el.address })}
                                    checked={addressId.value === el.fias_id ? true : false}
                                    onChange={(e) => handleChange(e.target.value)}
                                />
                                <span className="radio-button__mark" />
                                <p>{el.address}</p>
                            </label>
                        </div>
                    )}
                </div>
            </>}
        </div>
        <div className="steps">
            <div>
                {step !== 1 &&
                    <button
                        className="btn-prev"
                        onClick={() => {
                            setStep(step - 1)
                            resetCallback()
                        }}
                    >Назад</button>
                }
            </div>
            <div>
                {step === 3 && <button onClick={() => checkAddress()}>Оформить заказ</button>}
            </div>
        </div>
    </div>
    )
}

export default ConfirmAddress