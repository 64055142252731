import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";

function AsyncSelectComponent(props) {
    const { placeholder, handleChange, promiseOptions, defaultValue } = props;
    const [input, setInput] = useState("");
    const [inputSavedValue, setSavedValue] = useState("");

    useEffect(() => {
        setInput(defaultValue);
    }, [defaultValue]);
    return (
        <AsyncSelect
            cacheOptions
            loadOptions={promiseOptions}
            onChange={(value) => {
                handleChange(value);
                setSavedValue(value?.label);
            }}
            placeholder={placeholder}
            menuPlacement="auto"
            inputValue={input}
            onInputChange={setInput}
            closeMenuOnSelect
            blurInputOnSelect
            onFocus={() => {
                setInput(inputSavedValue);
            }}
        />
    );
}

export default AsyncSelectComponent;
